@import '~leaflet/dist/leaflet.css';

@font-face {
  font-family: poppins-bold;
  src: url('fonts/Poppins-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: poppins-medium;
  src: url('fonts/Poppins-Medium.ttf');
  font-weight: normal
}

@font-face {
  font-family: poppins-light;
  src: url('fonts/Poppins-Thin.ttf');
  font-weight: lighter
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip, .leaflet-popup-close-button {
  display: none;
}

.achievement {
  display: block;
  position: absolute;
  width: 50%;
  height: 40%;
  top: 30%;
  opacity: 1;
  transition: 0.3s;
  left: 25%;
  background-color: brown;
  color: white;
  z-index: 99999;
  pointer-events: none;

}

.h-1\/10 {
  height: 10%;
}
.h-9\/10 {
  height: 90%;
}

.mainBackDarkGreen {
  background-color: #0B3954;
}

.transparentMainBackGreen{
 background-color: rgba(8, 126, 139, 0.7);

}

.mainBackGreen{
 background-color: #087E8B;
}

.highlightText {
  color: #FF5A5F;
}

.mainGreen {
  color: #087E8B;
}

.secondaryColour {
  background-color: #FF5A5F;
}

.hidden {
  opacity: 0;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}  

.poppinsB{
  font-family: poppins-bold;
}

.poppinsM{
  font-family: poppins-medium;
}

.poppinsL{
  font-family: poppins-light;
}

.App {
  text-align: center;
}

h1.textmain {
  color: #E4D6A7;
}
h1.textsub {
  color: white;
}

.leaflet-container {
  height: 100vh;
}

.mapPanel {
  position: absolute;
  z-index: 9999;
  top: 50%;
  height: 50%;
  background-color: white;
  border-radius: 2rem 2rem 0 0;
  width: 100%;
}

.leaflet-div-icon {
  /* background: transparent; */
  border: 0px solid #666;
  /* opacity: 0; */
}

.example {
  height: 30vh;
  background-color: pink;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-container {
  height: 100%;
  width: 100%;
}
